<template>
  <div class="modal">
    <Modal v-model:active="activeModal" width="500" no-footer="true" style="padding: 0" top="auto">
      <div class="mail">
        <div class="titleMarker"></div>
        <div class="body">
          <div class="starup"><img :src="require('/src/assets/airdrop_redeem_starup.png')"></div>
          <div class="stardown"><img :src="require('/src/assets/airdrop_redeem_stardown.png')"></div>
          <div class="text">
            <a-input :style="{width:'320px' ,marginBottom: '20px'}" v-model="realName" :max-length="10" show-word-limit placeholder="请输入姓名" allow-clear>
              <template #prepend>
                姓名
              </template>
            </a-input>
            <a-input :style="{width:'320px' ,marginBottom: '20px'}" v-model="idCard" :max-length="18" show-word-limit placeholder="请输入身份证号" allow-clear>
              <template #prepend>
                身份证号
              </template>
            </a-input>
          </div>
        </div>
        <div class="footer">
          <a-row>
            <a-col span="12">
              <div class="cancel" @click="cancel">取消</div>
            </a-col>
            <a-col span="12">
              <div class="goLogin" @click="action">验证</div>
            </a-col>
          </a-row>
        </div>
        <div class="title">
          <a-row>
            <a-col span="6"></a-col>
            <a-col span="12">
              <div class="text"><span>实名验证</span></div>
            </a-col>
            <a-col span="2"></a-col>
            <a-col span="2">
              <div class="closeIcon" @click="cancel">
                <icon-close/>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import request from "/src/components/utils/axios";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'RealNameModal',
  props: ['active']
  , emits: ['update:active']
  , data() {
    console.log("activeModal:  " + this.active);
    return {
      // eslint-disable-next-line no-undef
      activeModal: this.active
      , realName: null
      , idCard: null
    }
  }
  , watch: {
    active(newVal){
      console.log(newVal)
      this.activeModal = newVal;
    }
  }
  , mounted() {
    $('.vxp-modal__content').css('padding', 0)
  }
  , methods: {
    cancel() {
      this.activeModal = false;
      this.$emit('update:active', false);
      console.log("activeModal:  " + this.activeModal);
    }
    , action() {
      request({
        url: '/user/realName'
        ,params: {
          realName: this.realName
          ,idCardNo: this.idCard
        }
      }).then(res => {
        if(res.code === 10000){
          this.$message.success('验证成功!');
          this.cancel();
        }else {
          this.$message.error('验证失败，请检查输入后重试!');
        }
      });
    }
  }
}
</script>
<style scoped>
.mail {
  background-color: #473cae !important;
  color: #fff;
  width: 100%;
}

.title {
  position: absolute;
  width: 100%;
  height: 60px;
  top: -30px;
  left: 80px;
  z-index: 1024;
  background: none !important;
}

.title .text {
  margin: 10px 0;
  font-size: 18px;
}

.title .closeIcon {
  position: absolute;
  background-color: #7b7b7b;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  top: 18px;
  right: 66px;
  cursor: pointer;
}

.titleMarker {
  height: 50px;
}

.body {
  height: 120px;
}

.body .text {
  margin-top: 40px;
  text-align: center;
  font-size: 14px;
}

.body .starup, .body .stardown {
  position: absolute;
}

.body .starup {
  top: 40px;
  left: 20px;
}

.body .stardown {
  top: 100px;
  right: 40px;
}

.footer {
  padding-bottom: 20px;
}

.footer .cancel, .footer .goLogin {
  border: 1px solid #999;
  margin: 0 50px;
  text-align: center;
  padding: 5px 0;
  font-size: 16px;
  cursor: pointer;
}

.footer .goLogin {
  background: radial-gradient(111.36% 1709.09% at 100% 111.36%, #5f35d5 0, #6433f3 27.1%, #8951ff 100%)
}
</style>