<template>
  <div class="left">
    <a-row>
      <a-col span="2"></a-col>
      <a-col span="20">
        <a-row>
          <a-col span="5">
            <div class="nav">
              <div class="aboutUs"  @click="changeItem('/aboutUs')">
                <div class="title">关于原视觉</div>
              </div>
              <div class="questions" @click="changeItem('/questions')">
                <div class="title">常见问题</div>
              </div>
              <div class="applyTobeArtist" @click="changeItem('/applyTobeArtist')">
                <div class="title">申请成为艺术家</div>
              </div>
              <div class="privacy" @click="changeItem('/privacy')">
                <div class="title">隐私政策</div>
              </div>
              <div class="terms" @click="changeItem('/terms')">
                <div class="title">用户协议</div>
              </div>
            </div>
          </a-col>
          <a-col span="19">
            <div class="_slot">
              <slot></slot>
            </div>
          </a-col>
        </a-row>
      </a-col>
      <a-col span="2"></a-col>
    </a-row>
  </div>
</template>

<script>
import global from "@/global";
import request from "/src/components/utils/axios.js";
import {baseURL, rewriteUrl} from '/src/components/utils/OssUtil'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'AboutLeft',
  components: {
  },
  props: {
    index: Number
  }
  , data() {
    // let _info = global.userinfo();
    // if (!_info) {
    //   return location.href = '/login';
    // }
    return {
      // userinfo: _info,
      // isLogin: _info.uid,
      // nickname: _info ? _info.nickname : '',
      // avatar: _info && _info.avatar ? _info.avatar : require('/src/assets/user_default_avatar.png'),
      // realNameVerification: _info.realNameVerification ? true : false,
      // realNameModalActive: false
    }
  }
  , mounted() {
    let i = this.index;
    if (i > -1) {
      $('.nav .title:eq(' + i + ')').addClass('active');
    }
  }
  , methods: {
    changeItem(url) {
      location.href = url;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.nav ,._slot{
  color: #fff;
  background-color: #221c2d;
  border-radius: 15px;
  margin-top: 80px;
}
.nav{
  margin-right: 70px;
}
._slot{

  /*margin-right: 70px;*/
}

.nav .title {
  font-size: 16px;
  padding: 25px;
  cursor: pointer;
  text-align: center;
}

.nav .title.active {
  background-color: #7358fe;
}

</style>
