<template>
  <div class="footer">
    <div class="_top">
      <a-row>
        <a-col :span="3"></a-col>
        <a-col class="logo" :span="4">
          <img alt="" src="../../assets/logo_static.png" decoding="async" data-nimg="fill" style="width: 11vw;height: 11vw">
        </a-col>
        <a-col :span="8"></a-col>
        <a-col :span="7" class="_list">
          <a-row>
            <a-col :span="8">
              <div>
                <div class="_title"><span>关于我们</span></div>
                <div><a href="/aboutUs">关于原始世界</a></div>
                <div><a href="/questions">常见问题</a></div>
              </div>
            </a-col>
            <a-col :span="8">
              <div>
                <div class="_title"><span>法律声明</span></div>
                <div><a href="/terms">用户协议</a></div>
                <div><a href="/privacy">隐私政策</a></div>
              </div>
            </a-col>
            <a-col :span="8">
              <div>
                <div class="_title"><span>联系我们</span></div>
                <div><a href="/applyTobeArtist">申请成为艺术家</a></div>
              </div>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="2"></a-col>
      </a-row>

    </div>
    <div class="_bottom">
      <span>©常州五百像素网络科技有限公司 苏网信备3204122297538761001X号</span>
      <a href="https://beian.miit.gov.cn/" rel="noreferrer" target="_blank">苏ICP备20016755号-1</a>
      <span> 苏B2-20200928</span>
      <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32041202001732" rel="noreferrer"
         target="_blank">
        苏公网安备 32041202001732号
      </a>
      <a href="https://yuan.500px.com.cn/pdf/ICP.pdf" rel="noreferrer" target="_blank">
        <!-- -->艺术品经营单位备 320412180022号<!-- -->
      </a>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Footer',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
  background-color: #1e1238;
  background-image: url("../../assets/footer_bg_mountain.png");
  background-repeat: no-repeat;
  background-size: 50vw 25vw;
  background-position: 20vw 4vw;
  height: 20vw;
  width: 100%;
  padding-top: 1vw;
}

.footer ._top {
  height: 18vw;
  width: 100%;
}

.footer .logo img {
  width: 11vw;
  height: 11vw;
}

.footer ._top ._list {
  height: 18vw;
  text-align: center;
  line-height: 3.8vw;
}
.footer ._top ._list ._title{
  color: #ffffff;
  font-size: large;
}
.footer ._top ._list a{
  color: rgba(148,151,148,0.89);
  font-size: medium;
  text-decoration: none;
}
.footer ._bottom{
  text-align: center;
}
.footer ._bottom a{
  text-decoration: none;
  padding: 0.5vw;
}
</style>
