<template>
  <div class="header">
    <a href="/" class="logo router-link-active" aria-current="page">
      <img alt="" src="../../assets/logoT.gif" decoding="async" data-nimg="fill">
    </a>

    <div class="menu">
      <a data-index="0" href="/">首页</a>
      <a data-index="1" href="/market">市场</a>
      <a data-index="2" href="/artist">艺术家</a>
      <a data-index="3" href="/aboutUs">关于我们</a>
    </div>

    <div class="_right">
      <div v-if="isLogin">
        <a-row>
          <a-col :span="5">
            <!--            <div :style="{width: '40px', height: '40px', borderRadius: '50%', overflow: 'hidden', padding: '0'}">-->
            <img :src="avatar" :style="{borderRadius: '50%' , width: '2vw' ,height: '2vw'}"/>
            <!--            </div>-->
          </a-col>
          <!--          <a-col :span="1"></a-col>-->
          <a-col :span="17">
            <a-row>
              <a-col :span="20">
                <div style="padding-top: 0.2vw; padding-left: 0.5vw;maxWidth: 9vw">
                  <Ellipsis><a href="/userinfo" :style="{color: '#ffffff' ,maxWidth: '9vw'}">{{ nickname }} </a>
                  </Ellipsis>
                </div>
              </a-col>
              <a-col :span="4">
                <div style="padding-top: 0.4vw; padding-left: 0.8vw;">
                  <a-popover>
                    <span style="color: #fff"><icon-down/></span>
                    <template #content>
                      <a-doption>
                        <a-button @click="logout">退出登录</a-button>
                      </a-doption>
                    </template>
                  </a-popover>
                </div>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
      <div v-if="!isLogin">
        <a-button><a href="/login">登录</a></a-button>
      </div>
      <div class="cart">
        <a href="/cart">
          <a-badge :max-count="9" :count="cartSize" :offset="[6, 20]" :dotStyle="{ width: '1.1vw', height: '1.2vw' }">
            <svg width="25" height="25" t="1678328767380" class="icon" viewBox="0 0 1024 1024" version="1.1"
                 xmlns="http://www.w3.org/2000/svg" p-id="3301">
              <path
                  d="M984 210.85H212.35L188.84 93.3a40.1 40.1 0 0 0-39.22-32.16H20a20 20 0 0 0 0 40h129.62l21.94 109.71 25.91 129.55L241.38 560l8 40 23.51 117.55a40.11 40.11 0 0 0 39.22 32.16h551.95a20 20 0 0 0 0-40H312.11L290.17 600h590.76a40 40 0 0 0 38-27.35l103-309.12A40 40 0 0 0 984 210.85zM880.93 560H282.17l-61.82-309.15H984z"
                  p-id="3302"></path>
              <path d="M393.64 885.04m-77.82 0a77.82 77.82 0 1 0 155.64 0 77.82 77.82 0 1 0-155.64 0Z"
                    p-id="3303"></path>
              <path d="M784.34 885.04m-77.82 0a77.82 77.82 0 1 0 155.64 0 77.82 77.82 0 1 0-155.64 0Z"
                    p-id="3304"></path>
            </svg>
          </a-badge>
          <div style="color: #fff; font-size: 0.8vw; line-height: 1.7vw;margin-left: 1vw">购物车</div>
        </a>
      </div>
      <div v-if="false">
        <button class="notice">
          <icon-notification size="30"/>
          <span style="color: #fff; font-size: 15px">消息通知</span>
          <a-badge :count="1" :dot="true"/>
        </button>
      </div>
      <div>
        <a-space direction="vertical" allow-clear="true">
          <a-input-search id="header_search" :style="{width:'17vw' ,height: '2vw'}" placeholder="请输入艺术品、艺术家名称" @search="search"/>
        </a-space>
      </div>
    </div>
  </div>
  <div class="header_space"></div>
</template>

<script>
import {IconPlus} from '@arco-design/web-vue/es/icon';
import global from "@/global";
import request from "/src/components/utils/axios.js";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Header',
  ws: null,
  props: {
    msg: String,
    index: Number,
  }
  , data() {
    let isLogin = global.isLogin();
    let _info = isLogin ? global.userinfo() : null;
    return {
      userinfo: _info,
      isLogin: isLogin,
      cartSize: 0,
      nickname: _info ? _info.nickname : '',
      avatar: _info && _info.avatar ? _info.avatar : require('/src/assets/user_default_avatar.png'),
    }
  }
  , components: {
    IconPlus
  }
  , mounted() {
    let i = this.index;
    if (i && i > -1) {
      $('.header .menu a:eq(' + i + ')').css('color', '#fff')
    }
    this.getCartSize();
    this.registerAddCart();
    this.registerResetCartSize();

    let _that = this;
    console.log(global.wsUrl)
    try {
      this.ws = new WebSocket(global.wsUrl);
      this.ws.onopen = function () {
        _that.login();
      };
      this.ws.onmessage = this.onmessage;
      this.ws.onclose = function () {
        console.error("ws close!!!");
      }
      this.ws.onerror = function (e) {
        console.error("ws error");
        console.error(e);
      }
    } catch (e) {
      console.error(e);
    }
  }
  , methods: {
    wsSend(msg) {
      msg = JSON.stringify(msg);
      console.log("send: " + msg);
      this.ws.send(msg);
    }
    , onmessage(message) {
      let msg = JSON.parse(message);
      console.log("onmessage: " + msg)
      global.emitEvent(msg.code, msg.data);
    }
    , login() {
      this.wsSend({
        'code': 'login',
        'data': {
          'token': global.token(),
        }
      })
    }
    , logout() {
      global.logout();
      request({
        url: '/login/logout',
      }).then(res => {
      });
      location.href = "/";
    }
    , search(val) {
      location.href = "/market?search=" + val;
    }
    , getCartSize() {
      if (this.isLogin) {
        request({
          url: '/cart/size',
          method: 'get'
        }).then(res => {
          if (res.code === 10000) {
            this.cartSize = res.data;
          }
        });
      }
    }
    , registerAddCart() {
      let that = this;
      global.onEvent('addCart', function (params) {
        if (!global.isLogin()) {
          location.href = '/login'
          return;
        }
        request({
          url: '/cart/add',
          method: 'post',
          params: params
        }).then(res => {
          if (res.code === 10000) {
            that.$message.success('添加成功!')
            that.getCartSize();
          }else if(res.response.status == 401){
            location.href = '/login';
          }
        });
      })
    }
    , registerResetCartSize() {
      let that = this;
      global.onEvent('resetCartSize', function (params) {
        that.getCartSize();
      })
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
body {
  margin: 0;
  padding: 0;
  background-color: #0f0e15;
}

a {
  text-decoration: none;
}

.header_space {
  height: 4.3vw;
  width: 100%;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  height: 4.3vw;
  width: 100%;
  background: radial-gradient(111.36% 1709.09% at 100% 111.36%, #5f35d5 0, #6433f3 27.1%, #8951ff 100%);
}

.header .logo {
  /*position: relative;;*/
  /*left: -50px;*/
}

.header .logo img {
  height: 3.5vw;
}

.header .menu {
  position: absolute;
  top: -1vw;
  left: 7vw;
  box-sizing: border-box;
  /*width: 500px;*/
  padding: 2.6vw 5vw;
  /*opacity: 0;*/
}

.header .menu a {
  padding: 1vw;
  font-size: 0.9vw;
  color: rgba(183, 186, 183, 0.89);
  text-decoration: none;
}

.header ._right {
  position: relative;
  top: -2.38vw;
}

.header ._right div {
  float: right;
  padding-right: 0.7vw;
}

.header ._right .notice {
  color: #ffffff;
  background: none;
  border: none;
  font-size: 16px;
}

.header ._right .notice:hover {
  cursor: pointer;
}

.header ._right .notice span {
  position: relative;
  top: -9px;
}

.header ._right .cart {
  cursor: pointer;
  /*border: 1px solid #999;*/
}

.header ._right .cart:hover {
  margin-top: 0.5vw;
}
</style>
