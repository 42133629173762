<template>
  <div class="_buy">
    <a-row>
      <a-col :span="6" v-if="float == 'right'"></a-col>
      <a-col :span="3" v-if="float == 'center'"></a-col>
      <a-col :span="10" v-if="(info.artistUid != info.holderUid || info.lockStatus != 1) && float == 'right'"></a-col>
      <a-col :span="10" v-if="info.artistUid == info.holderUid && info.lockStatus == 1">
        <div class="cartBtn" @click="addCart(info.nftId)" >
          加入购物车
        </div>
      </a-col>
      <a-col :span="8">
        <div class="buyBtn" @click="gotoInfo(info.nftId)"
             v-if="info.artistUid == info.holderUid && info.lockStatus == 1">
          立即购买
        </div>
        <div class="failure"
             v-if="info.artistUid == info.holderUid && info.lockStatus != 1">
          锁定中
        </div>
        <div class="failure" v-if="info.artistUid != info.holderUid">
          已售罄
        </div>
      </a-col>
      <a-col :span="3" v-if="float == 'center'"></a-col>
      <a-col :span="6" v-if="float == 'left'"></a-col>
    </a-row>
  </div>
</template>
<script>

import global from "@/global";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'BuyBtn',
  props: {
    info: {type: Object, default: () => {}}
    ,float: {type: String, default: () => 'right'}
  }
  , setup(props, ctx) {
  }
  , data() {
    return {
    }
  }
  , watch: {
  }
  , mounted() {
  }
  , methods: {
    addCart(artworkId) {
      global.emitEvent('addCart', {'artworkId': artworkId})
    }
    ,gotoInfo(nftId){
      location.href = global.viewUrl + '/product?nftId=' + nftId;
    }
  }
}
</script>
<style scoped>

._buy {
  padding-bottom: 10px;
}

._buy .buyBtn ,.cartBtn ,.failure{
  background-color: #7358fe;
  font-size: 14px;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 1px 2px 1px rgba(148, 151, 148, 0.89);
  margin-right: 5px;
}

.cartBtn {
  background: linear-gradient(90deg,#ff7a64,#e61f1a);
}

.failure{
  background: #999;
}
</style>