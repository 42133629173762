import axios from 'axios';
import global from "@/global";

axios.defaults.baseURL = global.apiUrl;

const service = axios.create({
    timeout: 40000,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': global.token()
    },
})
service.interceptors.request.use(
    config => {
        console.log(config)
        let token = global.token();
        if(token){
            config.headers['Authorization'] = token;
        }
        return config
    },
    error => {
        Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        console.error(error);
        return error;
    }
)
export default service
