<script>
// 定义一些公共的属性和方法

const viewUrl = ''; //http://localhost:8080
// const apiUrl = 'http://192.168.0.21:8890/nftmall';
// const wsUrl = 'ws://192.168.0.21:8890/nftmall/websocket';
const apiUrl = 'http://120.77.15.111:9890/nftmall';
const wsUrl = 'ws://120.77.15.111:9890/nftmall/websocket';

import $ from 'jquery'

const event = {};

const logout = function () {
  localStorage.removeItem('_token');
  localStorage.removeItem('_userinfo');
}

const token = function (tokeId) {
  if (tokeId) {
    let _t = {token: tokeId ,expire: Date.now() + 5 * 60 * 3000}
    localStorage.setItem('_token', JSON.stringify(_t));
  }
  let _token = localStorage.getItem('_token');
  try{
    let token = JSON.parse(_token);
    if(token.expire && token.expire > Date.now()){
      return token.token;
    }else {
      let isLogin = false;
      $.ajax({
        url: apiUrl + '/login/status',
        method: 'GET',
        async: false,
        headers: {
          'Authorization': token.token
        }
        , success: res => {
          if (res.code == 10000) {
            isLogin = res.data;
          }
        }
      });
      if(isLogin){
        token.expire = Date.now() + 5 * 60 * 3000;
        localStorage.setItem('_token', JSON.stringify(token));
        return token.token;
      }
      return null;
    }
  }catch (e){
    return _token;
  }
}

const latestUserinfo = function (checkLogin = false) {
  let info = {};
  $.ajax({
    url: apiUrl + '/user/account/info',
    method: 'GET',
    async: false,
    headers: {
      'Authorization': token()
    }
    , success: res => {
      info = res;
    }
  });
  if (info.code == 10000) {
    info = info.data;
    localStorage.setItem('_userinfo', JSON.stringify(info));
    token(info.tokenId);
    return info;
  }else if(checkLogin){
    location.href = "/login"
  }
  return {};
}

const userinfo = function (info ,checkLogin = false) {
  if (info) {
    localStorage.setItem('_userinfo' ,JSON.stringify(info));
    token(info.tokenId);
    return info;
  } else {
    let val = localStorage.getItem('_userinfo');
    if (val) {
      return JSON.parse(val);
    }else if(checkLogin){
      location.href = '/login';
    }
    return {};
  }
}

const isLogin = function () {
  let t = token();
  return t && t.length > 0 ;
}


const goBuy = (nftIds) => {
  sessionStorage.setItem('_nft_ids', JSON.stringify(nftIds));
  location.href = '/buy'
}

const onEvent = function (key, func) {
  event[key] = func;
}

const emitEvent = function (key, obj) {
  if (event[key]) {
    event[key](obj);
  }
}


// 暴露出这些属性
export default {
  viewUrl, apiUrl, wsUrl, token, isLogin, userinfo ,latestUserinfo , logout, onEvent, emitEvent, goBuy
}
</script>